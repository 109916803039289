@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: #735951;
        --foreground: #0b1f33;

        --muted: #f0f4f8;
        --muted-foreground: #7d8b99;

        --popover: #ffffff;
        --popover-foreground: #0b1f33;

        --border: #e9eef3;
        --input: #e9eef3;

        --card: #ffffff;
        --card-foreground: #0b1f33;

        --primary: #0b1f33;
        --primary-foreground: #f8fafe;

        --secondary: #f0f4f8;
        --secondary-foreground: #0b1f33;

        --accent: #f0f4f8;
        --accent-foreground: #0b1f33;

        --destructive: #ff0000;
        --destructive-foreground: #f8fafe;

        --ring: #a0b4cc;

        --radius: 0.5rem;
    }

    .dark {
        --background: #05060b;
        --foreground: #d4e3f1;

        --muted: #0d1826;
        --muted-foreground: #9db0c4;

        --accent: #1d2a3b;
        --accent-foreground: #f8fafe;

        --popover: #05060b;
        --popover-foreground: #a0b4cc;

        --border: #1d2a3b;
        --input: #1d2a3b;

        --card: #05060b;
        --card-foreground: #d4e3f1;

        --primary: #f8fafe;
        --primary-foreground: #00030b;

        --secondary: #0b1f33;
        --secondary-foreground: #f8fafe;

        --destructive: #990000;
        --destructive-foreground: #f8fafe;

        --ring: #1d2a3b;

        --radius: 0.5rem;
    }


}

@layer components {
    html:has(.drawer-auto-gutter .drawer-toggle:checked) {
        scrollbar-gutter: auto;
    }

    .menu-title {
        font-size: 1.25rem;
        font-weight: 700;
    }

    .menu-item {
        font-size: 1rem;
        font-weight: 400;
        padding: none;
    }
}